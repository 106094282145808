import { graphql, useStaticQuery } from 'gatsby'

import Img from 'gatsby-image'
import React from 'react'

export const MobileImage = props => {
    const data = useStaticQuery(graphql`
    query {
        file(relativePath: { eq: "WarrantyOptions_responsive_Format.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 608, maxHeight: 406) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
    `)

    return <Img
        fluid={data.file.childImageSharp.fluid}
        {...props}
        style={{
            maxWidth: '608px',
            maxHeight: '406px',
            ...props.style
        }}
    />
}