import './styles.scss'
import '@Components/Pages/Warranty/styles.scss'

import { Image, MobileImage } from '@Components/Pages/Warranty'

import { ILendingLogo } from '@Components/shared/Images'
import MenuHeader from '@Components/shared/MenuPieces/MenuHeader'
import React from 'react'

const Warranty = () =>
  <div className='menu-page'>
    <div className='menu-page-warranty'>
      <MenuHeader title='Warranty Options' className='menu-page-warranty-header' />
      <MobileImage className='menu-page-warranty-img-mobile' />
      <div className='menu-page-warranty-text'>
        <div className='menu-page-warranty-text-text'>
          <p>
            Have you considered paying a small monthly fee so you don't have to worry about any surprise auto repair bills?
            Don't have your budget blown by an unexpected repair. Our partner, iLendingDirect's, Vehicle Service Contracts give
            you peace of mind; the cost can be included right into your loan.
          </p>
          <p>
            The number of problems with your car increases as it gets older. Even the most common repairs, such as replacing or
            repairing a transmission cost nearly $2,500 on average. Over the next five years, the cost to repair your vehicle will
            increase by approximately 12% per year. To put it in perspective, most of our Vehicle Service Contracts cost just $1.48 per day!
          </p>
        </div>
        <Image className='menu-page-warranty-text-img' />
      </div>
      <hr />
      <ILendingLogo className='menu-page-ilending-logo' alt='menu-page-ilending-logo' />
      <a href='https://ilendingdirect.com' target='_blank' rel='noopener noreferrer'>
        <div className='menu-page-button' href='https://ilendingdirect.com' target='_blank' rel='noopener noreferrer'>
          Visit iLendingDIRECT
          </div>
      </a>
    </div>
  </div>

export default Warranty
